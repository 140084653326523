<template>
  <div class="orderFollowPeo">
    <el-card class="box-card father-card" shadow="never">
      <el-row class="btns">
        <el-form ref="form" :model="form" inline>
          <el-form-item label="订单编号">
            <el-input v-model="form.orderNo" placeholder="请输入订单编号" clearable></el-input>
          </el-form-item>
          <el-form-item prop="orderCompany" label="机构">
            <el-select
                       v-model="form.orderCompany"
                       placeholder="请选择机构名称"
                       clearable>
              <el-option v-for="(regionItem, index) in orderCompanyList"
                         :key="index"
                         :label="regionItem.companyName"
                         :value="regionItem.uuid"></el-option>
            </el-select>
          </el-form-item>
          <!-- 按钮区域 -->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <el-row class="tab">
        <!-- 表格区域 -->
        <el-table
          ref="multipleTable"
          :data="List"
          tooltip-effect="dark"
          style="width: 100%"
          highlight-current-row
          stripe
          border
          :header-cell-style="rowClass"
        >
          <el-table-column prop="orderNo" label="订单编号" align="center"></el-table-column>
          <el-table-column prop="carNum" label="车牌号" align="center"></el-table-column>

          <el-table-column prop="driverName" label="司机" align="center"></el-table-column>
          <el-table-column prop="doctorList" label="随车医生" align="center">
            <template slot-scope="scope">
                <div v-if="scope.row.doctorNameList&&scope.row.doctorNameList.length!=0">
                     <span v-for="(item,index) in scope.row.doctorNameList" :key="index">{{item}} &nbsp;</span>
                </div>
            </template>
          </el-table-column>
          <el-table-column prop="nurseList" label="随车护士" align="center">
             <template slot-scope="scope">
                <div v-if="scope.row.doctorNameList&&scope.row.nurseNameList.length!=0">
                     <span v-for="(item,index) in scope.row.nurseNameList" :key="index">{{item}} &nbsp;</span>
                </div>
            </template>
          </el-table-column>
          <el-table-column prop="raiseNameList" label="搬抬人员" align="center"></el-table-column>
          <el-table-column prop="escortNameList" label="护送人员" align="center"></el-table-column>
          <el-table-column prop="orderDate" label="订单时间" align="center"></el-table-column>
          <el-table-column prop="remark" label="备注" align="center"></el-table-column>
          <el-table-column label="详情" align="center">
            <template slot-scope="scope">
              <el-button size="mini" @click="look(scope.row)" type="success">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handlePageNumChange"
          :current-page="paging.PageNo"
          :page-sizes="[5, 10, 20, 50]"
          :page-size="paging.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
        <!-- 订单详情弹出框 -->
        <el-dialog :visible.sync="orderDialogFormVisible" class="order-dialog">
          <span slot="title" class="dialog-Title">即时订单详情</span>
          <el-form :model="orderForm" inline ref="orderForm">
            <el-row>
              <el-form-item label="订单编号" prop="orderNo">
                <el-input v-model="orderForm.orderNo" autocomplete="off" disabled></el-input>
              </el-form-item>
              <el-form-item label="司机" prop="driverName">
                <el-input v-model="orderForm.driverName" autocomplete="off" disabled></el-input>
              </el-form-item>
              <el-form-item label="随车医生" prop="doctorList">
                <el-input v-model="orderForm.doctorNameList" autocomplete="off" disabled></el-input>
              </el-form-item>
              <el-form-item label="随车护士" prop="nurseList">
                <el-input v-model="orderForm.nurseNameList" autocomplete="off" disabled></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="搬抬人员" prop="raiseList">
                <el-input v-model="orderForm.raiseList" autocomplete="off" disabled></el-input>
              </el-form-item>
              <el-form-item label="护送人员" prop="escortList">
                <el-input v-model="orderForm.escortList" autocomplete="off" disabled></el-input>
              </el-form-item>
               <el-col :span="12">
                          <el-form-item label="订单时间" prop="orderDate">
                            <el-input v-model="orderForm.orderDate" autocomplete="off" disabled></el-input>
                        </el-form-item>
                </el-col>

            </el-row>
            <el-row :gutter="10">
                <el-form-item label="备注" prop="remark">
                <el-input   type="textarea"  v-model="orderForm.remark" autocomplete="off" disabled></el-input>
</el-form-item>
            </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="orderDialogFormVisible=false">确 认</el-button>
          </div>
        </el-dialog>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import store from '@/store/index.js'

export default {
  name: 'orderFollowPeo',
  components: {},
  data () {
    return {
      List: [], // 表格数据源
      form: { orderNo: '' }, // 搜索表单
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      total: 0, // 总的数据条数
      orderDialogFormVisible: false,
      orderForm: {},
      orderCompanyList: [] // 机构列表
    }
  },
  created () {
    this.getOrderList()
    this.getorderCompany()
  },
  methods: {
    // 按地区获取机构--调试完成
    async getorderCompany (e) {
      const {
        data: res
      } = await this.$http.post('/userManageServer/tSysCompany/selectCompanyDataAuthScope', {
        headers: {
          uuid: store.state.order_company || sessionStorage.getItem('order_company'),
          AUTH_TYPE: 'company'
        }
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取机构失败')
      this.orderCompanyList = res.data
    },
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getOrderList()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getOrderList()
    },
    // 获取订单随车人员
    async getOrderList () {
      const { data: res } = await this.$http.post('/pmService/tInfoOrder/listDispatchPerson', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        ...this.form
        // orderType: '0',
        // status: '1' // (0:新加,1:派单，2:接单，3：变更，4:已支付，5:撤消，6:已退款，7:完成,8代付预付费,9到达出发地,10到达目的地)
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取订单列表失败')
      this.List = res.data.records
      this.total = res.data.total
      console.log(res)
    },
    // 点击搜索按钮触发
    async search () {
      // 判断有输入账号
    //   if (this.form.orderNo === '') {
    //     this.getOrderList()
    //   } else {
      // 调接口，进行查询
      this.paging.PageNo = 1
      const { data: res } = await this.$http.post('/pmService/tInfoOrder/listDispatchPerson', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        //   orderType: '0',
        //   status: '1', // (0:新加,1:派单，2:接单，3：变更，4:已支付，5:撤消，6:已退款，7:完成,8代付预付费,9到达出发地,10到达目的地)
        //   orderNo: this.form.orderNo
        ...this.form
      })
      console.log(res)
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('查询失败')
      // this.clientList = res.data
      // this.total = +res.pageBean.pageDataCount
      this.List = res.data.records
      this.total = res.data.total
    //   }
    },
    // 点击查看详情按钮触发
    look (v) {
      this.orderForm = v
      this.orderDialogFormVisible = true
    }
  }

}
</script>
<style lang="less" scoped>
.orderFollowPeo {
  width: 100%;
  height: 100%;
  .father-card {
    .btns {
      margin-bottom: 10px;
    }
    .tab {
      /deep/ .el-table {
        margin-bottom: 20px;
        .el-table__row--striped td {
          background-color: #e4eaec !important;
        }
        .current-row > td {
          background-color: #66b1ff !important;
        }
      }
      .el-pagination {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
        margin-right: 0.25rem;
        text-align: right;
      }
      .order-dialog {
        .dialog-Title {
          color: #0097fe;
          font-size: 20px;
          font-weight: 700;
        }
        /deep/ .el-form {
          .subtitle {
            color: #0097fe;
            font-size: 14px;
            font-weight: 700;
            // margin: 15px 0;
            margin-bottom: 20px;
            padding-left: 6px;
            border-left: 5px solid #0097fe;
          }
          .el-row {
            // flex: 1;
            display: flex;
            // justify-content: space-between;
            .el-form-item {
              // width: 50%;
              flex: 1;
              display: flex;
              // text-align: left;
              .el-form-item__content {
                flex: 1;
                .el-input {
                  width: 100%;
                  .el-input__inner {
                    width: 100%;
                  }
                }
                .el-select {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
